
import { defineComponent, ref, Ref } from "vue";
import { Image, getBase64, State } from "@/models/uploader";
import { Toast } from "@/lib/toast";
import { apiPost } from "@/lib/api_post";
import { userStore } from "@/store/user";
export default defineComponent({
  name: "Uploader",
  emits: ["update:imageList"],
  setup(props, { emit }) {
    const imageList: Ref<Image[]> = ref([]);
    const upload = async (file: File) => {
      // filesize <= 10gb
      if (file.size > 1024 * 1024 * 1025 * 10) {
        Toast.info("檔案最大不可超過10gb");
      } else {
        const base64EncodedImage = await getBase64(file);
        const newImage = new Image(base64EncodedImage);
        imageList.value.push(newImage);
        await transfer(file, newImage);
      }
      return Promise.reject();
    };
    const transfer = async (file: File, image: Image): Promise<boolean> => {
      const res = await apiPost.uploadFile(
        { fileName: file.name },
        userStore.ginToken.value
      );
      const fd = new FormData();
      const signMap = res.getSignedPostPolicyMap();
      signMap.forEach((value, key) => {
        fd.append(key, value);
      });
      fd.append("file", file);

      image.path = res.getDisplayImagePath();

      return new Promise((resolve, reject) => {
        const request = new XMLHttpRequest();
        request.open("POST", res.getUrlToUpload());
        image.state = State.uploading;

        request.upload.addEventListener("progress", (e) => {
          if (e.lengthComputable) {
            var uploadPercent = e.loaded / e.total;
            uploadPercent = Math.ceil(uploadPercent * 100);
          }
        });

        request.addEventListener("readystatechange", async (ev) => {
          if (
            request.readyState === XMLHttpRequest.DONE &&
            request.status === 200
          ) {
            image.state = State.uploaded;
            Toast.info(`${file.name} 檔案上傳完成`);
            emit("update:imageList", imageList.value as Image[]);
            resolve(true);
          }
        });
        request.send(fd);
      });
    };
    const remove = (image: Image) => {
      const foundIndex = imageList.value.findIndex((e) => e === image);
      imageList.value.splice(foundIndex, 1);
      return;
    };
    return { imageList, upload, remove };
  },
});
