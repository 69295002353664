
import { defineComponent, reactive, toRefs, watch } from "vue";
import { debounce } from "lodash";
import { apiCompany } from "@/lib/api_gin";
import { userStore } from "@/store/user";
import { MyAgent } from "@/models/post";
export default defineComponent({
  name: "StaffSelector",
  emits: ["update:staffList"],
  setup(props, { emit }) {
    const __state: {
      staffList: MyAgent[];
      isFetching: boolean;
      temp: MyAgent[];
    } = {
      staffList: [],
      isFetching: false,
      temp: [],
    };
    const state = reactive(__state);

    const fetchStaffList = debounce(async (search: string) => {
      state.staffList = [];
      if (search) {
        state.isFetching = true;
        const res = await apiCompany.getStaffList(
          {
            name: search,
            status: true,
          },
          userStore.ginToken.value
        );
        state.staffList = res.getDataList().map(
          (e) =>
            new MyAgent({
              uid: e.getUid(),
              objectId: e.getObjectId(),
              name: e.getDisplayName(),
              avatar: e.getAvatarUrl(),
            })
        );
        state.isFetching = false;
      }
    }, 300);

    const handleChange = (objectIds: string[]) => {
      const found = state.staffList.filter((e) =>
        objectIds.includes(e.objectId)
      );
      state.temp.push(...found);
      emit(
        "update:staffList",
        found.filter((e) => objectIds.includes(e.objectId))
      );
    };

    return {
      ...toRefs(state),
      fetchStaffList,
      handleChange,
    };
  },
});
