import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createSlots as _createSlots } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_spin = _resolveComponent("a-spin")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!

  return (_openBlock(), _createBlock(_component_a_select, {
    mode: "multiple",
    placeholder: "請選擇經紀窗口",
    style: {"width":"100%"},
    "filter-option": false,
    "not-found-content": _ctx.isFetching ? undefined : null,
    onSearch: _ctx.fetchStaffList,
    onChange: _ctx.handleChange,
    "allow-clear": ""
  }, _createSlots({
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.staffList, (lady) => {
        return (_openBlock(), _createBlock(_component_a_select_option, {
          key: lady.objectId,
          value: lady.objectId
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(lady.name), 1)
          ]),
          _: 2
        }, 1032, ["value"]))
      }), 128))
    ]),
    _: 2
  }, [
    (_ctx.isFetching)
      ? {
          name: "notFoundContent",
          fn: _withCtx(() => [
            _createVNode(_component_a_spin, { size: "small" })
          ]),
          key: "0"
        }
      : undefined
  ]), 1032, ["not-found-content", "onSearch", "onChange"]))
}