
import { defineComponent, ref, Ref } from "vue";
import dayjs, { Dayjs } from "dayjs";
export default defineComponent({
  name: "SingleDateRangePicker",
  emits: ["update:time"],
  setup(props, { emit }) {
    const state: Ref<{
      date: Dayjs;
      timeRangeFrom: Dayjs | undefined;
      timeRangeTo: Dayjs | undefined;
    }> = ref({
      date: dayjs(),
      timeRangeFrom: undefined,
      timeRangeTo: undefined,
    });

    const handleChange = () => {
      // if (state.value.timeRangeTo?.format("HH:mm:ss") === "00:00:00") {
      //   state.value.timeRangeTo = state.value.timeRangeTo.endOf("date");
      // }
      const from = state.value.timeRangeFrom?.startOf("hour");
      const to = state.value.timeRangeTo?.startOf("hour");
      if (from && to) {
        if (to.format("HH:mm") === "00:00") {
          const resultFrom = dayjs(
            state.value.date.format("YYYY/MM/DD ") +
              from.format("HH:mm:ss +08:00")
          );
          const resultTo = dayjs(
            state.value.date.format("YYYY/MM/DD ") +
              to.endOf("day").format("HH:mm:ss +08:00")
          );
          emit("update:time", [resultFrom, resultTo]);
        } else {
          if (from.isAfter(to)) {
            const temp = from.clone();
            state.value.timeRangeFrom = to.clone();
            state.value.timeRangeTo = temp.clone();
          }
          const resultFrom = dayjs(
            state.value.date.format("YYYY/MM/DD ") +
              state.value.timeRangeFrom?.format("HH:mm:ss +08:00")
          );
          const resultTo = dayjs(
            state.value.date.format("YYYY/MM/DD ") +
              state.value.timeRangeTo?.format("HH:mm:ss +08:00")
          );
          emit("update:time", [resultFrom, resultTo]);
        }
      }
    };
    return { state, handleChange };
  },
});
