
import { apiPost } from "@/lib/api_post";
import {
  MyActivity,
  MyCurrency,
  MyPostType,
  PostForm,
  MyAgent,
} from "@/models/post";
import { userStore } from "@/store/user";
import { defineComponent, reactive, toRefs, ref, onMounted } from "vue";
import { Rule } from "ant-design-vue/es/form";
import dayjs, { Dayjs } from "dayjs";
import StaffSelector from "@/components/StaffSelector.vue";
import Uploader from "@/components/Uploader.vue";
import SingleDateRangePicker from "@/components/SingleDateRangePicker.vue";
import { Image } from "@/models/uploader";
export default defineComponent({
  name: "PostForm",
  props: {
    originalPostForm: { type: PostForm },
  },
  emits: ["submit", "validate"],
  components: {
    StaffSelector,
    Uploader,
    SingleDateRangePicker,
  },
  setup(props, { emit }) {
    const __options: {
      activityList: MyActivity[];
      postTypeList: MyPostType[];
      currencyList: MyCurrency[];
    } = {
      activityList: [],
      postTypeList: [],
      currencyList: [],
    };
    const options = reactive(__options);
    const state = reactive({
      isLoading: false,
      action: "validate" as "validate" | "submit",
    });
    const postForm = ref(new PostForm());
    const getOptions = async () => {
      options.activityList = await apiPost
        .getActivityList(userStore.ginToken.value)
        .then((res) => res.getItemsList().map((e) => new MyActivity(e)));
      options.postTypeList = await apiPost
        .getPostItemList(userStore.ginToken.value)
        .then((res) => res.getItemsList().map((e) => new MyPostType(e)));
      options.currencyList = await apiPost
        .getCurrencyList(userStore.ginToken.value)
        .then((res) => res.getCurrencyList().map((e) => new MyCurrency(e)));
    };
    onMounted(async () => {
      state.isLoading = true;
      await getOptions();
      postForm.value.postTypeId = options.postTypeList[0].id;
      postForm.value.currencyId = options.currencyList[0].id;
      if (props.originalPostForm) {
        postForm.value = props.originalPostForm;
      }
      state.isLoading = false;
    });
    const clickSubmitButton = () => {
      const submitButton = document.querySelector(
        "#post-form-submit-button"
      ) as HTMLButtonElement;
      submitButton.click();
    };
    const submit = () => {
      state.action = "submit";
      clickSubmitButton();
    };
    const validate = () => {
      state.action = "validate";
      clickSubmitButton();
    };
    const onFinish = async () => {
      emit(state.action, postForm.value);
    };

    const updateStaffList = (staffList: MyAgent[]) => {
      postForm.value.staffList = staffList;
      console.log(postForm.value.staffList);
    };
    const validateNumberAbove1 = async (_rule: Rule, value: number) => {
      if (value > 0) {
        return Promise.resolve();
      } else {
        return Promise.reject("必須大於0");
      }
    };
    const validateTime = async (
      _rule: Rule,
      value: [Dayjs | undefined, Dayjs | undefined] | undefined
    ) => {
      console.log("validateTime: ", value);
      if (value && value[0] && value[1]) {
        return Promise.resolve();
      } else {
        return Promise.reject("請輸入時間");
      }
    };
    const rules: Record<string, Rule[]> = {
      price: [{ validator: validateNumberAbove1, trigger: "change" }],
      vacancyAmount: [{ validator: validateNumberAbove1, trigger: "change" }],
      time: [{ validator: validateTime, trigger: "change" }],
    };

    const updateTime = (range: [Dayjs | undefined, Dayjs | undefined]) => {
      postForm.value.time = range;
      validate();
    };

    const updateImageList = (imageList: Image[]) => {
      postForm.value.imageList = imageList;
    };

    return {
      ...toRefs(state),
      ...toRefs(options),
      postForm,
      submit,
      validate,
      updateStaffList,
      rules,
      Image,
      updateTime,
      onFinish,
      updateImageList,
    };
  },
});
